import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      margin: 'auto',
      maxWidth: 800,
      '& > *': {
        margin: theme.spacing(0.5),
      },
      justifyContent: 'right'
    },

  }));



export default function LinkBar() {

    const classes = useStyles();


    return (
        <Box m={1} pt={1} justifyContent="center">
            <div className={classes.root}>
            <IconButton onClick={() => {window.open("https://www.linkedin.com/in/jonathan-rossy-5360a2b6/", "_self")}}><LinkedInIcon/></IconButton>
            <IconButton onClick={() => {window.location.href='mailto:jonfenrossy@gmail.com'}}><EmailIcon/></IconButton>
            <IconButton onClick={() => {window.open('tel:4388211123')}}><CallIcon/></IconButton>   
               
               
            </div>
        </Box>
    );
  
  }