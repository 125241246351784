import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { blue, red, green, purple} from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      margin: 'auto',
      maxWidth: 800,
      '& > *': {
        margin: theme.spacing(0.5),
      },
      justifyContent: 'right'
    },

  }));

const GreenRadio = withStyles({
    root: {
        color: green[400],
        '&$checked': {
        color: green[600],
        },
    },
    checked: {},
})((props) => <Radio  color="default" {...props} />);

const BlueRadio = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
        color: blue[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

const RedRadio = withStyles({
    root: {
        color: red[400],
        '&$checked': {
        color: red[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

const PurpleRadio = withStyles({
    root: {
        color: purple[400],
        '&$checked': {
        color: purple[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

export default function FilterBar({filters, setFilters}) {

    const classes = useStyles();

    const handleChange = (event) => {
        setFilters(event.target.value);
    };
    

    return (
        <Box m={1} pt={1} justifyContent="center">
            <div className={classes.root}>
                <FormControlLabel
                    control={<GreenRadio 
                                checked={filters === "product"} 
                                onChange={handleChange} 
                                name="product"
                                value="product"
                            />}
                    label="Product Management"
                />
                <FormControlLabel
                    control={<BlueRadio checked={filters === "education"} onChange={handleChange} name="education" value="education"/>}
                    label="Education"
                />
                <FormControlLabel
                    control={<RedRadio checked={filters === "adventure"} onChange={handleChange} name="adventure"  value="adventure"/>}
                    label="Adventure"
                />

                <FormControlLabel
                    control={<PurpleRadio checked={filters === "projects"} onChange={handleChange} name="projects" value="projects"/>}
                    label="Projects"
                />
            </div>
        </Box>
    );
  
  }