import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, rgbToHex } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import {useState} from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paperRed: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 800,
      border: '2px solid'
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 800,
      },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  }));

export default function Card({content, color}) {

    const classes = useStyles();

    const [cursor, setCursor] = useState(null);
    const [backgroundColor, setBackgroundColor] = useState("rgba(254, 254, 254, 0.1)");

    const changeCursor = () => {
      setCursor(prevState => {
        if(prevState === null && content["url"] != null){
          setBackgroundColor("rgba(0, 0, 0, 0.04)");
          return 'pointer';
        }
        setBackgroundColor("rgba(254, 254, 254, 0.1)");
        return null;
      });
    }

    return (
        <Box m={1} pt={1} >
        <div className={classes.root}>
            <Paper 
              className={classes.paperRed} 
              style={{borderColor : color,
                    cursor: cursor,
                    backgroundColor: backgroundColor
                    }} 
              variant="outlined"  
              onClick={() => {if(content["url"] != null){window.open(content["url"], "_self")}}}
              onMouseEnter={changeCursor}
              onMouseLeave={changeCursor}
            >

                <Grid container spacing={1}>
                    

                    <Grid item xs={12} sm container>


                        <Grid item xs container direction="column" spacing={1}>

                            <Grid item xs>
                                <Typography gutterBottom variant="h6">
                                {content["title"]}
                                </Typography>
                                <Typography gutterBottom variant="subtitle2">
                                {content["company"]}
                                </Typography>
                            </Grid>

                            <Grid item xs>

                                <Typography variant="body1" gutterBottom>
                                {content["description"]}
                                </Typography>
        
                            </Grid>


                        </Grid>

                        <Grid item>
                            <Typography variant="subtitle1">{content["date_start"]}-{content["date_end"]}</Typography>
                        </Grid>

                    </Grid>
                </Grid>

            </Paper>
        </div>
        </Box>
    );
  
  }