import React, {useEffect, useState} from 'react';
import './App.css';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Container from '@material-ui/core/Container';

import Card from './components/Card';
import FilterBar from './components/FilterBar';
import LinkBar from './components/LinkBar';

import data from "./data/data.json";




const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#37474f',
    },
    secondary: {
      main: '#f50057',
    },
    success: {
      main: '#4caf50',
    },
  },
  typography: {
    h2: {
      fontSize: '4rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 500,
    },
    body1: {
      lineHeight: 1.18,
      fontWeight: 400,
      fontSize: '0.9rem',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor : "#ffffff"
  },

}));

function App() {

  const [filters, setFilters] = useState("product")

  const classes = useStyles();
  
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root} >
        <Container maxWidth="md"  >
          <Box my={8} align="center"  >
            <Typography variant="h2">
              Jonathan Rossy
            </Typography>
            <LinkBar/>
          </Box>



          <Box my={0} align="center">
            <FilterBar
              filters={filters}
              setFilters={setFilters}
            />
          </Box>




          {data.map(dat => {
                if (filters === "education" && dat["categories"]["education"] === true){
                  return <Card content={dat} color={"rgba(33, 150, 243, 0.5)"}/>
                }
                if (filters === "product" && dat["categories"]["product"] === true){
                  return <Card content={dat} color={"rgba(76, 175, 80, 0.5)"}/>
                }
                if (filters === "adventure" && dat["categories"]["adventure"] === true){
                  return <Card content={dat} color={"rgba(244, 67, 54, 0.5)"}/>
                }
                if (filters === "projects" && dat["categories"]["projects"] === true){
                  return <Card content={dat} color={"rgba(156, 39, 176, 0.5)"}/>
                }
                
          })}



        </Container>
      </div>
    </ThemeProvider>
    
  );
}

export default App;
